@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* Color Theme Swatches in Hex */
$duotone-contrast-pastel-1-hex: #011826;
$duotone-contrast-pastel-2-hex: #025E73;
$My-Color-Theme-3-hex: #024959;
$duotone-contrast-pastel-4-hex: #E0AD9E;
$duotone-contrast-pastel-5-hex: #D98179;

/* Color Theme Swatches in Hex */
$green-2-hex: #222B56;
$My-Color-Theme-3-hex: #0B4683;
$My-Color-Theme-3-hex: #1A9695;
$My-Color-Theme-4-hex: #D69510;
$My-Color-Theme-5-hex: #F2D678;

/* Color Theme Swatches in Hex */
$green-1-hex: #98C195;
$green-2-hex: #5C9E6B;
$green-3-hex: #147135;
$green-4-hex: #1C5C59;
$green-5-hex: #1C3A3E;

$bright-font: #fff;
$dark-font: #000;

.custom-ratio-4x3 {
  @include media-breakpoint-up(md) {
    --bs-aspect-ratio: 13%; // 2x1
  }
}


body {
  font-family: 'Roboto', sans-serif;
  padding-top: 2rem;
  background-color: #f9f9f9;
}

footer {
  color: #FFFFFF;
}

h2 {
  font-size: 25px;
}

a {
  text-decoration: none;
}

.navbar {
  background-color: $green-3-hex;
  border-bottom-color: $green-3-hex;
}

.card-header {
  background-color: $green-3-hex;
  color: $bright-font;
}

.nav-link, .nav-link:hover {
  color: $bright-font;
}

.nav-item:hover {
  background-color: $green-2-hex;
}

.green {
  color: green;
}

.red {
  color: red;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#container a{
  display:inline-block;
  vertical-align:top;
}

.server-name-max-length {
  max-width: 400px;
}

.server-version-max-length {
  max-width: 100px;
}

.server-list-title {
  display: block;
  font-size: 14px;
}

.custom-badge {
  a:link {
    color: #fff;
  }

  /* visited link */
  a:visited {
    color: #fff;
  }

  /* mouse over link */
  a:hover {
    color: #fff;
  }

  /* selected link */
  a:active {
    color: #fff;
  }
}